<template>
  <div>
    <h1 class="text-center font-ubuntu pb-12">
      <span class="block text-white font-light text-shadow text-3xl sm:text-4xl lg:text-5xl">PhotoSapiens are a </span>
      <span class="block text-white font-medium text-4xl sm:text-6xl lg:text-7xl text-shadow">New Species Of Humans</span>
      <span class="block text-white font-light text-shadow text-3xl sm:text-4xl lg:text-5xl">with an identity on the blockchain</span>
    </h1>
    <div class="flex justify-center mb-8">
      <button class="btn-content" @click="mintNow" :class="{'cursor-not-allowed':!isActive}">Mint Now</button>
    </div>
    <div class="px-4">
      <div class="transparent-block  text-center rounded-md py-8">
        <div class="block">
          <div class="flex justify-center flex-wrap">
            <img src="@/assets/images/samples/PhotoChromic_1.png" class="w-44 p-1" alt="">
            <img src="@/assets/images/samples/PhotoChromic_3.png" class="w-44  p-1" alt="">
            <img src="@/assets/images/samples/PhotoChromic_4.png" class="w-44  p-1" alt="">
            <img src="@/assets/images/samples/PhotoChromic_5.png" class="w-44  p-1" alt="">
            <img src="@/assets/images/samples/PhotoChromic_2.png" class="w-44  p-1" alt="">
            <img src="@/assets/images/samples/PhotoChromic_6.png" class="w-44  p-1" alt="">
            <img src="@/assets/images/samples/PhotoChromic_7.png" class="w-44  p-1" alt="">
            <img src="@/assets/images/samples/PhotoChromic_8.png" class="w-44  p-1" alt="">
            <img src="@/assets/images/samples/PhotoChromic_9.png" class="w-44  p-1" alt="">
            <img src="@/assets/images/samples/PhotoChromic_10.png" class="w-44  p-1" alt="">
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
const emitter = require('tiny-emitter/instance');

export default {
  props: {},
  setup()
  {
    let isActive = true;


    function mintNow()
    {
      if (isActive) {
        window.location.href = "https://mint.photosapiens.io/";
      } else {
        emitter.emit('fire-alert', 'Minting is not open yet', 'notice')
      }

    }

    return { mintNow, isActive }
  }

}
</script>